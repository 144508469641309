<template>
    <main class="form-signin" v-if="!in_manutencao">
        <form @submit.stop.prevent="submit">
            <div class="form-floating">
                <img src="http://credenciamentosus.com.br/credenciamento.png" alt="Credenciamento SUS" />
            </div>
            <div class="form-floating">
                <h1 class="h3 mb-3 fw-normal">Credenciamento SUS</h1>
            </div>
            <div class="alert alert-danger" v-show="mostra_errors">
                <ul>
                    <li v-for="(error) in errors" :key="index">{{ error }}</li>
                </ul>
            </div>
            <div class="form-floating">
                <input type="email" class="form-control" v-model="in_email" id="in_email" placeholder="name@example.com">
                <label for="in_email">Email</label>
            </div>
            <div class="form-floating">
                <input type="password" class="form-control" v-model="in_password" id="in_password" placeholder="Senha">
                <label for="in_password">Senha</label>
            </div>
            <button class="w-100 btn btn-lg btn-primary" type="submit">Entrar</button>
            <br />
            <div class="form-floating" style="margin-top: 10px;">
                <router-link to="/recuperarasenha">Esqueci minha senha</router-link>
            </div>
            <br>
            <div class="form-floating" style="margin-top: -10px;">
                <router-link to="/novomembro">Registrar um novo membro</router-link>
            </div>
        </form>
    </main>
    <main v-else>
        <div class="form-floating">
            <img src="http://credenciamentosus.com.br/credenciamento.png" alt="Credenciamento SUS" />
        </div>
        <div class="form-floating">
            <div style="text-align: left;">
                <h1 class="h3 mb-3 fw-normal">Prezados Usuários,</h1>
                <p>Informamos que o sistema <strong>CREDENCIAMENTO SUS</strong> estará aprimorando suas funcionalidades para melhor atendê-los.
                </p>
                <p>Por isso o sistema ficará fora do ar a partir das 08h00min deste sábado, 24 de junho de 2023.</p>
                <p>Voltaremos ao normal às 16 horas de hoje.</p>
                <p>Agradecemos a compreensão!</p>
            </div>
        </div>
    </main>
</template>
<script>
import axios from '@/services/https.js';
import Cookie from 'js-cookie';
import router from '@/router/index';

export default {
    name: 'LoginView',
    data() {
        return {
            in_email: '',
            in_password: '',
            in_manutencao: false,
            errors: [],
            mostra_errors: false
        }
    },
    created() {
        const token = Cookie.get('_my_token');
        if (token) {
            router.replace({ name: 'home' });
        }
    },
    methods: {
        async submit() {

            let self = this;

            await axios.post('/login',
                {
                    in_email: self.in_email,
                    in_password: self.in_password
                })
                .then(function (response) {
                    var dados = response.data;
                    Cookie.set('_my_token', dados.access_token);
                    Cookie.set('_email_', self.in_email);
                    Cookie.set('_acesso_adm_', self.in_acesso_adm);
                    Cookie.set('_in_acesso_ger_', self.in_acesso_ger);
                    Cookie.set('_nm_usuario_', self.nm_usuario);

                    router.replace({ name: 'home' });
                })
                .catch(function (error) {
                    if (error.response.status == 422) {
                        self.errors = error.response.data.errors;
                    } else if (error.response.status == 401) {
                        self.errors = ['Credenciais de acesso inválida!'];
                    } else if (error.response.status == 500) {
                        self.errors = ['Servidor recusou solicitação!'];
                    } else if (error.response.status == 200) {
                        self.errors = ['Erro Conexão!'];
                    } else {
                        self.errors = [error.code];
                    }
                    self.success = false;
                    self.mostra_errors = true;
                });
        }
    }
}
</script>
<style>
.form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
}

.form-signin .form-floating:focus-within {
    z-index: 2;
}

.form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.form-floating {
    padding: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: auto;
}

img {
    width: 180px;
}
</style>