import axios from 'axios';

const axiosInstance = axios.create({
    baseURL : 'http://backend.credenciamentosus.com.br/api',
    //baseURL : 'http://127.0.0.1:8000/api',
    headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods' : '*',
        'Access-Control-Allow-Headers' : '*',
        'Access-Control-Allow-Credentials' : true
    }
});

export default axiosInstance;