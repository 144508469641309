<template>
    <div class="municipio">
        <span v-if="nm_municipio"><i class="fa-solid fa-tree-city"></i>{{ nm_municipio }}</span>
    </div>
    <div class="usuario">
        <span><i class="fa-solid fa-user"></i>{{ nm_usuario }}</span>
    </div>
    <div class="sair">
        <a href="#" @click="logout"><i class="fa-solid fa-right-from-bracket"></i>Sair</a>
    </div>
</template>
<script>
import axios from '@/services/https.js';
import Cookie from 'js-cookie';
import router from '@/router/index';
export default {
    name: 'NaveBarComponent',
    data() {
        return {
            nm_usuario: '',
            nm_municipio: ''
        }
    },
    created() {
        if(Cookie.get('_email_'))
            this.listaUserSessao();
    },
    methods: {
        async logout() {

            Cookie.remove('_my_token');
            Cookie.remove('_tp_pessoa_');
            Cookie.remove('_name_');
            Cookie.remove('_cpf_');
            Cookie.remove('_email_');
            Cookie.remove('_acesso_prof_');
            Cookie.remove('_acesso_adm_');
            Cookie.remove('_acesso_ger_');
            Cookie.remove('_id_user_');
            Cookie.remove('_id_citie_');
            Cookie.remove('_municipio_');

            localStorage.removeItem('user');

            router.push({ path: '/' });
        },
        async listaUserSessao() {

            let self = this;

            const params = {
                token: Cookie.get('_my_token'),
                in_email: Cookie.get('_email_'),
            }

            await axios.post('/listaUser',params)
                .then(function (response) {
                    if (response.data.status == 'Token is Expired') {
                        self.logout();
                    } else {
                        var dados = response.data[0];
                        Cookie.set('_id_user_', dados.id);
                        Cookie.set('_name_', dados.nm_usuario);
                        Cookie.set('_tp_pessoa_', dados.tp_pessoa);
                        self.nm_usuario = dados.nm_usuario;
                        Cookie.set('_cpf_', dados.nr_cpf);
                        Cookie.set('_acesso_prof_', dados.in_acesso_prof);
                        Cookie.set('_acesso_adm_', dados.in_acesso_adm);
                        Cookie.set('_acesso_ger_', dados.in_acesso_ger);
                        Cookie.set('_id_citie_', dados.id_cidade);
                        Cookie.set('_municipio_', dados.nm_municipio);
                        Cookie.set('_uf_', dados.in_uf);
                        self.nm_municipio = dados.nm_municipio;
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 422) {
                        self.errors = error.response.data.errors;
                    } else if (error.response.status == 401) {
                        self.errors = ['Credenciais de acesso inválida!'];
                    } else if (error.response.status == 500) {
                        self.errors = ['Servidor recusou solicitação!'];
                    }
                });

        }
    }
}
</script>