<style lang="scss" src="./styles/main.scss"/>
<template>
  <component :is="layout">
    <router-view/>
  </component>
</template>
<script>
  import { computed } from 'vue';
  import {useRouter} from 'vue-router';
  import DefaultLayoutComponent from '@/components/layouts/DefaultLayoutComponent'
  import EmptyLayoutComponent from '@/components/layouts/EmptyLayoutComponent'

  export default{
    components:{
      DefaultLayoutComponent,
      EmptyLayoutComponent
    },
    setup(){

      const defaultLayout = 'default';
      const { currentRoute } = useRouter();

      const layout = computed(
         () => `${currentRoute.value.meta.layout || defaultLayout}-layout-component`
      );

      return {
        layout
      };
    }
  }
</script>
<style>
  span[role="alert"],
  span.required {
      color: red;
      font-size: small;
  }
  span.required {padding-left: 3px;}
</style>