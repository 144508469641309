import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VeeValidate from 'vee-validate';
import PrimeVue from 'primevue/config';
import InputMask from 'primevue/inputmask';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js'

createApp(App)
    .use(VeeValidate, PrimeVue)
    .component('InputMask', InputMask)
    .use(router)
    .mount('#app');