<template>
    <nav>
        <div>
            <router-link to="/home"><span><i class="fa-solid fa-house"></i>Início</span></router-link>
        </div>
        <div v-if="dados.acesso_ger">
            <span class="titulo">Parametrização</span>
            <div><router-link to="/gestor"><span><i class="fa-solid fa-user-pen"></i>Gestor</span></router-link></div>
            <div><router-link to="/documentos"><span><i class="fa-solid fa-file-invoice"></i>Documentos</span></router-link></div>
        </div>
        <div v-if="dados.acesso_adm">
            <span class="titulo">Área administrativa</span>
            <div><router-link to="/listaIncricoes"><span><i class="fa-solid fa-spell-check"></i>Lista Inscrições</span></router-link></div>
            <div><router-link to="/editais"><span><i class="fa-solid fa-file-pen"></i>Edital</span></router-link></div>
            <div><router-link to="/especialidades"><span><i class="fa-solid fa-suitcase-medical"></i>Especialidades</span></router-link></div>
            <div><router-link to="/usuarios"><span><i class="fa-solid fa-user-pen"></i>Usuário Administrador</span></router-link>
            </div>
        </div>
        <div v-if="dados.acesso_prof">
            <span class="titulo">Área Profissional</span>
            <div><router-link to="/listaEditais"><span><i class="fa-solid fa-magnifying-glass"></i>Lista Editais</span></router-link></div>
            <div><router-link to="/inscricoes"><span><i class="fa-solid fa-check"></i>Inscrições</span></router-link></div>
            <div><router-link to="/profissional"><span><i class="fa-solid fa-user-tie"></i>Profissional</span></router-link></div>
            <div><router-link to="/senha"><span><i class="fa-solid fa-key"></i>Senha</span></router-link></div>
        </div>
    </nav>
</template>
<script>
import axios from '@/services/https.js';
import Cookie from 'js-cookie';
export default {
    name: 'MenuComponent',
    data() {
        return {
            dados: {
                acesso_ger: 0,
                acesso_adm: 0,
                acesso_prof: 0
            }
        }
    },
    created() {
        if(Cookie.get('_email_'))
            this.listaPermissao();
    },
    methods: {
        async listaPermissao() {

            let self = this;

            const params = {
                token: Cookie.get('_my_token'),
                in_email: Cookie.get('_email_'),
            }

            await axios.post('/listaUser', params)
                .then(function (response) {
                    if (response.data.status === 'Token is Expired' || response.data.status === 'Authorization Token not Found') {
                        self.logout();
                    } else {
                        var dados = response.data[0];
                        self.dados.acesso_ger = dados.in_acesso_ger;
                        self.dados.acesso_adm = dados.in_acesso_adm;
                        self.dados.acesso_prof = dados.in_acesso_prof;
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 422) {
                        self.errors = error.response.data.errors;
                    } else if (error.response.status == 401) {
                        self.errors = ['Credenciais de acesso inválida!'];
                    } else if (error.response.status == 500) {
                        self.errors = ['Servidor recusou solicitação!'];
                    }
                });
        }
    }
}
</script>
<style lang="scss">
nav {
    padding: 5px;
    display: block;
    font-size: 12px;

    div {
        span.titulo {
            width: 100%;
            background-color: rgba(255, 255, 255, 0.171);
            color: hsla(0, 0%, 100%, .87);
            display: inline-block;
            padding: 5px;
        }

        a {
            color: hsla(0, 0%, 100%, .6);

            span {
                width: 100%;
                padding: 5px;
                display: inline-block;
            }

            span:hover {
                background-color: hsla(0, 0%, 100%, .05);
                color: hsla(0, 0%, 100%, .87);
                border-radius: 5px;
            }

            svg {
                padding-right: 5px;
            }
        }
    }

}
</style>