import Cookies from 'js-cookie';

export default{
    auth(to,from,next){
        const token = Cookies.get('_my_token');
        if(!token){
            next('/');
        }
        next();
    }
}