<template>
    <div class="topo">
        <span>Credenciamento SUS</span>
    </div>
    <div class="menu">
       <MenuComponentVue/>       
    </div>
</template>
<script>
import MenuComponentVue from './MenuComponent';
export default{
    name: 'SideBarComponent',
    components:{
        MenuComponentVue
    }
}</script>
<style lang="scss">
</style>