<template>
    <div class="dashboard">
        <main>
            <div class="sidebar">
                <SideBarComponentVue/>
            </div>
            <div class="content-right">
                <div class="navbar">
                    <NavBarComponent/>
                </div>
                <div class="bd-content">
                    <slot></slot>
                </div>
            </div>
        </main>
    </div>
</template>
<script>
import SideBarComponentVue from '../SideBarComponent';
import NavBarComponent from '../NavBarComponent';
export default{
    name:'DashboardComponent',
    components:{
        SideBarComponentVue,
        NavBarComponent
    }
}
</script>