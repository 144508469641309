import LoginViewVue from '@/views/Login/LoginView.vue'
import { createRouter, createWebHistory } from 'vue-router'
import Guard from '../services/middleware'

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginViewVue,
    meta:{layout: 'empty'},
  },
  {
    path: '/novomembro',
    name: 'novomembro',
    component: () => import(/* webpackChunkName: "novomembro" */ '../views/Login/NovoMembroView.vue'),
    meta:{layout: 'empty'}
  },
  {
    path: '/recuperarasenha',
    name: 'recuperarasenha',
    component: () => import(/* webpackChunkName: "recuperarasenha" */ '../views/Login/RecuperarSenhaView.vue'),
    meta:{layout: 'empty'}
  },
  {
    path: '/home',
    name: 'home',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
    meta:{layout: 'default'}
  },
  {
    path: '/inscricoes',
    name: 'inscricoes',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "inscricoes" */ '../views/Inscricoes/InscricoesView.vue'),
    meta:{layout: 'default'}
  },
  {
    path: '/listaIncricoes',
    name: 'listaIncricoes',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "listaIncricoes" */ '../views/Inscricoes/ListaInscricoesView.vue'),
    meta:{layout: 'default'}
  },
  {
    path: '/listaIncricoesEspld/:id_edital',
    name: 'listaIncricoesEspld',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "listaIncricoes" */ '../views/Inscricoes/ListaInscricoesEspldView.vue'),
    meta:{layout: 'default'}
  },
  {
    path: '/listaInscritos/:id_edital/:id_espld/:nr_carga_hr/:tp_carga_hr/:nm_especialidade',
    name: 'listaInscritos',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "listaIncricoes" */ '../views/Inscricoes/ListaInscritosView.vue'),
    meta:{layout: 'default'}
  },
  {
    path: '/inscritos/:id_inscricao/:id_cidade/:nm_especialidade',
    name: 'inscritos',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "listaIncricoes" */ '../views/Inscricoes/InscritoView.vue'),
    meta:{layout: 'default'}
  },
  {
    path: '/editais',
    name: 'editais',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "editais" */ '../views/Editais/EditaisView.vue'),
    meta:{layout: 'default'}
  },
  {
    path: '/editalnovo',
    name: 'editalnovo',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "editalnovo" */ '../views/Editais/EditalNovoView.vue'),
    meta:{layout: 'default'}
  },
  {
    path: '/editaleditar/:id',
    name: 'editaleditar',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "editaleditar" */ '../views/Editais/EditalEditarView.vue'),
    meta:{layout: 'default'}
  },
  {
    path: '/editalexcluir/:id',
    name: 'editalexcluir',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "editalexcluir" */ '../views/Editais/EditalExcluirView.vue'),
    meta:{layout: 'default'}
  },
  {
    path: '/especialidades',
    name: 'especialidades',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "especialidades" */ '../views/Especialidades/EspecialidadesView.vue'),
    meta:{layout: 'default'}
  },
  {
    path: '/especialidadenovo',
    name: 'especialidadenovo',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "especialidadenovo" */ '../views/Especialidades/EspecialidadeNovoView.vue'),
    meta:{layout: 'default'}
  },
  {
    path: '/especialidadeexcluir/:id',
    name: 'especialidadeexcluir',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "especialidadeexcluir" */ '../views/Especialidades/EspecialidadeExcluirView.vue'),
    meta:{layout: 'default'}
  },
  {
    path: '/documentos',
    name: 'documentos',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "documentos" */ '../views/Documentos/DocumentosView.vue'),
    meta:{layout: 'default'}
  },
  {
    path: '/documentonovo',
    name: 'documentonovo',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "documentonovo" */ '../views/Documentos/DocumentoNovoView.vue'),
    meta:{layout: 'default'}
  },
  {
    path: '/documentoexcluir/:id',
    name: 'documentoexcluir',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "documentoexcluir" */ '../views/Documentos/DocumentoExcluirView.vue'),
    meta:{layout: 'default'}
  },
  {
    path: '/editalxespecialidades/:id',
    name: 'editalxespecialidades',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "editalxespecialidades" */ '../views/EditaisxEspecialidades/EditaisxEspecialidadesView.vue'),
    meta:{layout: 'default'}
  },
  {
    path: '/editalxdocumentos/:id',
    name: 'editalxdocumentos',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "editalxdocumentos" */ '../views/EditaisxDocumentos/EditaisxDocumentosView.vue'),
    meta:{layout: 'default'}
  },
  {
    path: '/editalxatas/:id',
    name: 'editalxatas',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "editalxespecialidades" */ '../views/EditaisxAtas/EditaisxAtasView.vue'),
    meta:{layout: 'default'}
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "usuarios" */ '../views/Usuarios/UsuariosView.vue'),
    meta:{layout: 'default'}
  },
  {
    path: '/usuarionovo',
    name: 'usuarionovo',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "usuarionovo" */ '../views/Usuarios/UsuarioNovoView.vue'),
    meta:{layout: 'default'}
  },
  {
    path: '/usuarioexcluir/:id',
    name: 'usuarioexcluir',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "usuarioexcluir" */ '../views/Usuarios/UsuariosExcluirView.vue'),
    meta:{layout: 'default'}
  },
  {
    path: '/listaEditais',
    name: 'listaEditais',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "listaEditais" */ '../views/Editais/ListaEditaisView.vue'),
    meta:{layout: 'default'}
  },
  {
    path: '/editalinscricao/:id/:id_cidade',
    name: 'editalinscricao',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "editaleditar" */ '../views/Editais/EditalInscricaoView.vue'),
    meta:{layout: 'default'}
  },
  {
    path: '/profissional',
    name: 'profissional',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "profissional" */ '../views/Profissionais/ProfissionaisView.vue'),
    meta:{layout: 'default'}
  },
  {
    path: '/profissionaldados',
    name: 'profissionaldados',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "profissional" */ '../views/Profissionais/ProfissionaisEditView.vue'),
    meta:{layout: 'default'}
  },
  {
    path: '/senha',
    name: 'senha',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "senha" */ '../views/Usuarios/UsuarioSenhaView.vue'),
    meta:{layout: 'default'}
  },
  {
    path: '/:pathMatch(.*)',
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "PageNotFound" */ '../views/Errors/error404.vue'),
    meta:{layout: 'empty'}
  },
  {
    path: '/gestor',
    name: 'gestor',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "gestor" */ '../views/Gestor/GestorView.vue'),
    meta:{layout: 'default'}
  },
  {
    path: '/gestornovo',
    name: 'gestornovo',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "gestornovo" */ '../views/Gestor/GestorNovoView.vue'),
    meta:{layout: 'default'}
  },
  {
    path: '/gestorexcluir/:id',
    name: 'gestorexcluir',
    beforeEnter: Guard.auth,
    component: () => import(/* webpackChunkName: "gestorexcluir" */ '../views/Gestor/GestorExcluirView.vue'),
    meta:{layout: 'default'}
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router